.codeSnippet {
  display: block;
  margin: 20px 10px;
}
  .codeSnippet code {
    padding: 10px 20px;
  }
  .codeSnippet span.snippet {
    margin-right: 10px;
  }
  .codeSnippet code button {
    vertical-align: middle;
  }