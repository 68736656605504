div.customer {
  float: left;
}
div.customerAssigned {
  margin-right: 100px;
  height: 40px;
  padding: 4px 0;
  float: right;
}
  div.customerAssigned span {
    margin-left:10px;
  }