.settings-fields > div:not(:last-child) {
  margin-bottom: 1.4em;
}

.settings-fields-label {
  font-weight: 600;
}

.settings-field-read-only-text {
  margin: 1.2rem 0 2.4rem !important;
}

.settings-fields > div:last-child .settings-field-read-only-text {
  margin-bottom: 0.8rem !important;
}

.settings-button-group {
  margin-top: 2rem;
}