div.agentAssigned {
  margin-right: 100px;
  height: 40px;
  padding: 10px 0;
  float: right;
}
  div.agentAssigned span {
    margin-left:10px;
  }
div.customer {
  float: left;
}
div.customerAssigned {
  margin-right: 100px;
  height: 40px;
  padding: 4px 0;
  float: right;
}
  div.customerAssigned span {
    margin-left:10px;
  }
.codeSnippet {
  display: block;
  margin: 20px 10px;
}
  .codeSnippet code {
    padding: 10px 20px;
  }
  .codeSnippet span.snippet {
    margin-right: 10px;
  }
  .codeSnippet code button {
    vertical-align: middle;
  }
.settings-fields > div:not(:last-child) {
  margin-bottom: 1.4em;
}

.settings-fields-label {
  font-weight: 600;
}

.settings-field-read-only-text {
  margin: 1.2rem 0 2.4rem !important;
}

.settings-fields > div:last-child .settings-field-read-only-text {
  margin-bottom: 0.8rem !important;
}

.settings-button-group {
  margin-top: 2rem;
}
body {
  margin: 0;
  padding: 0;
}
div.center {
  text-align: center;
}
div.loading {
  margin: 80px auto;
}
